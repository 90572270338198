import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { lastValueFrom, map, Observable, of, Subscription } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  format,
  parse,
  addDays,
  isPast,
  differenceInCalendarDays,
  parseISO,
  differenceInDays,
} from 'date-fns';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { CheckboxComponent } from 'src/app/shared/components/checkbox/checkbox.component';
import { DataService } from 'src/app/services/data.service';
import { NAVIGATION_MAP, PAGES } from 'src/app/shared/constants/navigation-map';
import { AppointmentInfo } from 'src/app/services/data.model';
import { FormData } from 'src/app/services/data.model';
import { NextButtonStateService } from 'src/app/services/next-button-state.service';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { TimerService } from 'src/app/services/timer.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PplCardComponent } from 'src/app/shared/components/ppl-card/ppl-card.component';
import { TimerComponent } from 'src/app/shared/components/timer/timer.component';
import { ActivatedRoute } from '@angular/router';
import { marketingAttributes } from 'src/app/shared/constants/marketing-params';

interface TimeSlot {
  startTime: string;
  id: string;
  endTime: string;
  periodOfTheDay: string;
}

interface TimeSlotExt extends TimeSlot {
  time: string;
}

interface TimeBlock {
  period: string;
  slots: TimeSlotExt[];
}

interface AppointmentItem {
  date: string;
  dayOfTheWeek: string;
  timeBlocks: TimeSlot[];
}

interface AppointmentItemExt extends AppointmentItem {
  formattedDate: string;
  timeSlots: TimeBlock[];
  disabled: boolean;
}

@Component({
  selector: 'app-time-date-booking',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    MainLayoutComponent,
    ReactiveFormsModule,
    CheckboxComponent,
    PplCardComponent,
    TimerComponent
  ],
  providers: [ContentstackQueryService],
  templateUrl: './time-date-booking.component.html',
  styleUrl: './time-date-booking.component.scss',
})
export class TimeDateBookingComponent implements OnInit, OnDestroy {
  pageContent: any = [];
  scheduleForm: FormGroup;
  calendarData = {};
  periods = ['Morning', 'Afternoon', 'Evening'];

  activeDate = format(new Date(), 'yyyy-MM-dd');
  selectedTime = ''; // time ID
  startDay = format(new Date(), 'yyyy-MM-dd');
  previouslyFilledData: AppointmentInfo;
  activeChunkNumber = 0;
  isDataLoaded = false;
  numberOfDays = 14;
  chunkSize = 3;
  numberOfChunks = Math.ceil(this.numberOfDays / this.chunkSize);
  totalCapacity = this.numberOfChunks * this.chunkSize;
  unusedElements = this.totalCapacity - this.numberOfDays;
  isPPL = false;
  showNotification: boolean = false;
  fadeClass: string = '';
  displayedPromotion: boolean = false;
  private timeoutId: any;
  subscriptions: Subscription = new Subscription();

  constructor(
    private contentstack: ContentstackQueryService,
    private fb: FormBuilder,
    private dataService: DataService,
    private timerService: TimerService,
    private navigationService: NavigationService,
    public nextButtonState: NextButtonStateService,
    private route: ActivatedRoute
  ) {
    this.getEntry();
  }

  ngOnInit() {
    this.scheduleForm = this.fb.group({
      selectedTime: ['', Validators.required],
      selectedTimeDate: ['', Validators.required],
      selectedStartTime: [''],
      selectedEndTime: [''],
      noAvailableTimeSlots: [false],
      noneWorksForMe: [false],
    });

    const timerOkButtonSub = this.timerService.okButtonClick$.subscribe(() => {
      this.navigationService.navigateToPath('time-date-booking');
      this.unselectTime();
    });
    this.subscriptions.add(timerOkButtonSub);
  }

  get getSlots() {
    // chunk
    const chunk = this.calendarData[this.activeChunkNumber] || [];
    // day
    const day = chunk?.find(
      (el: AppointmentItem) => el.date === this.activeDate
    );
    // time slots
    const timeSlots = day?.timeSlots || [];
    const slots = this.periods.reduce(
      (acc, curr) => {
        const slot = timeSlots.find(
          (sl: TimeBlock) => sl.period?.toLowerCase() === curr.toLowerCase()
        );
        if (slot) {
          acc.slots.push(slot);
          acc.slotsCount += slot.slots.length;
        } else {
          acc.slots.push({ period: curr, slots: [] });
        }
        return acc;
      },
      { slots: [], slotsCount: 0 }
    );
    console.log('slots', slots)
    return slots;
  }

  getEntry(): void {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/time-date-booking' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          if (this.dataService.id) {
            const stepsDatasub = this.dataService
              .getStepsData(this.dataService.id)
              .subscribe((data) => {
                if (data?.appointmentRequest?.isPPL) {
                  this.isPPL = data?.appointmentRequest?.isPPL;
                  this.numberOfDays = 7;
                  this.numberOfChunks = Math.ceil(
                    this.numberOfDays / this.chunkSize
                  );
                  this.totalCapacity = this.numberOfChunks * this.chunkSize;
                  this.unusedElements = this.totalCapacity - this.numberOfDays;
                }
                this.getCalendarData(
                  data?.appointmentRequest?.property?.zipcode
                );
                this.prePopulateStepData(data);
                this.displayedPromotion =
                  !!data?.appointmentRequest?.appointment?.schedulingPromotion
                    ?.displayedPromotion;
              });
            this.subscriptions.add(stepsDatasub);
          }
        },
        (err) => {}
      );
  }

  async getCalendarData(zipCode: string) {
    const dataArray = [];
    let date = this.startDay;
    for (let index = 0; index < this.numberOfChunks; index++) {
      dataArray.push(lastValueFrom(this.makeApiRequest(date, zipCode)));
      const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
      const updatedDate = addDays(parsedDate, 3);
      date = format(updatedDate, 'yyyy-MM-dd');
    }
    const data = await Promise.all(dataArray);
    data.forEach((el, index) => {
      this.calendarData[index] = this.prepareDays(el.appointmentBlocks);
    });
    this.findActiveChunk();
    this.checkIfNoSlots();
  }

  updateFormValues() {
    this.scheduleForm.patchValue({
      selectedTime: this.previouslyFilledData?.selectedTime || '',
      selectedTimeDate: this.previouslyFilledData?.selectedTimeDate || '',
      selectedStartTime: this.previouslyFilledData?.selectedStartTime || '',
      selectedEndTime: this.previouslyFilledData?.selectedEndTime || '',
      noAvailableTimeSlots:
        this.previouslyFilledData?.noAvailableTimeSlots || false,
      noneWorksForMe: this.previouslyFilledData?.noneWorksForMe || false,
    });
    this.checkFormValidity();
  }

  prePopulateStepData(data: FormData) {
    const sessionData = JSON.parse(data.sessionData);
    if (
      sessionData.appointmentInfo &&
      sessionData.appointmentInfo.selectedTimeDate
    ) {
      // validate if the previously selected date is in the past and at least 1 day
      const past = isPast(
        `${sessionData.appointmentInfo?.selectedTimeDate}T${sessionData.appointmentInfo?.selectedEndTime}`
      );
      const diff = differenceInCalendarDays(
        this.activeDate,
        sessionData.appointmentInfo?.selectedTimeDate
      );
      if (!past || (past && diff < 1)) {
        this.previouslyFilledData = sessionData.appointmentInfo;
        this.updateFormValues();
        this.selectedTime = this.previouslyFilledData?.selectedTime || '';
        this.activeDate = this.previouslyFilledData?.selectedTimeDate || '';
        this.findActiveChunk();
        this.checkIfNoSlots();
      }
    }
    this.isDataLoaded = true;
    this.subscribeToFormChanges();
  }

  findActiveChunk() {
    Object.keys(this.calendarData).forEach((key) => {
      const isActiveChunk = this.calendarData[key].some(
        (el: AppointmentItem) => el.date === this.activeDate
      );
      if (isActiveChunk) {
        this.activeChunkNumber = Number(key);
      }
    });
  }

  subscribeToFormChanges(): void {
    const scheduleFormSub = this.scheduleForm.valueChanges.subscribe((value) => {
      this.checkFormValidity();
    });
    this.subscriptions.add(scheduleFormSub);
  }

  checkFormValidity() {
    setTimeout((_) => {
      if (
        !this.scheduleForm.valid &&
        (this.scheduleForm.controls['noAvailableTimeSlots'].value ||
          this.scheduleForm.controls['noneWorksForMe'].value)
      ) {
        this.nextButtonState.emitActivateNext(true);
      } else {
        this.nextButtonState.emitActivateNext(this.scheduleForm.valid);
      }
    });
  }

  selectTime(period: { id: string; startTime: string; endTime: string }, day: { date: string; disabled: boolean }): void {
    if (!day.disabled) {
      this.activeDate = day.date;
    }
    this.timerService.stop();
    this.timerService.removeAlert();
    this.timerService.start();
    this.selectedTime = period.id;
    this.scheduleForm.patchValue({
      selectedTime: period.id,
      selectedTimeDate: this.activeDate,
      selectedStartTime: period.startTime,
      selectedEndTime: period.endTime,
      noAvailableTimeSlots: false,
      noneWorksForMe: false,
    });
    this.checkSelectedDay();
  }

  unselectTime(){
    this.selectedTime = '';
    this.scheduleForm.patchValue({
      selectedTime: '',
      selectedTimeDate: '',
      selectedStartTime: '',
      selectedEndTime: '',
    });
  }

  checkSelectedDay() {
    const timeToShow = 60000; // 1 minute
    if (
      this.isDateBeyond3Days() &&
      !(
        this.displayedPromotion ||
        this.dataService.stepsData?.appointmentRequest?.appointment
          ?.schedulingPromotion?.displayedPromotion
      )
    ) {
      this.toggleNotification();
      this.timeoutId = setTimeout((_) => {
        this.toggleNotification();
      }, timeToShow);
    }
  }

  cancelNotificationTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  isDateBeyond3Days() {
    const selectedDate = parseISO(this.scheduleForm.value.selectedTimeDate);
    const startDate = parseISO(this.startDay);
    const diffInDays = differenceInDays(selectedDate, startDate);

    return !!(diffInDays > 2);
  }

  toggleNotification() {
    if (this.showNotification) {
      this.fadeClass = 'fade-out';
      setTimeout(() => {
        this.showNotification = false;
      }, 500);
    } else {
      this.showNotification = true;
      this.displayedPromotion = true;
      setTimeout((_) => {
        this.fadeClass = 'fade-in';
      });
    }
  }

  setActiveDay(day: { date: string; disabled: boolean }): void {
    if (!day.disabled) {
      this.activeDate = day.date;
    }
  }

  checkIfNoSlots(): void {
    let slotsCount = 0;
    let dayNumber = 1;
    Object.keys(this.calendarData).forEach((key) => {
      this.calendarData[key].forEach((day) => {
        if (dayNumber <= this.numberOfDays) {
          if (day && day.timeBlocks && day.timeBlocks.length) {
            slotsCount = slotsCount + day.timeBlocks.length;
          }
          dayNumber++;
        }
      });
    });
    if (!slotsCount) {
      this.scheduleForm.patchValue({ noAvailableTimeSlots: true });
    } else {
      this.scheduleForm.patchValue({ noAvailableTimeSlots: false });
    }
  }

  goForward(): void {
    this.activeChunkNumber = this.activeChunkNumber + 1;
    this.activeDate = this.calendarData[this.activeChunkNumber][0].date;
  }

  goBackward(): void {
    this.activeChunkNumber = this.activeChunkNumber - 1;
    this.activeDate = this.calendarData[this.activeChunkNumber][0].date;
  }

  onClickNext = (force?: boolean): Observable<any> => {
    if (force) {
      return this.saveData(
        NAVIGATION_MAP[PAGES.TIME_DATE_BOOKING].nextButton.route,
        true
      );
    } else if (this.scheduleForm.valid) {
      return this.saveData(
        NAVIGATION_MAP[PAGES.TIME_DATE_BOOKING].nextButton.route,
        true
      );
    } else {
      this.scheduleForm.markAllAsTouched();
      return of(false);
    }
  };

  onClickBack = (): Observable<any> => {
    return this.scheduleForm.valid
      ? this.saveData(NAVIGATION_MAP[PAGES.TIME_DATE_BOOKING].backButton.route)
      : of(true);
  };

  saveData(nextPage, isNext?): Observable<any> {
    const stepsData = { ...this.dataService.stepsData };

    const sessionData = JSON.parse(stepsData.sessionData);
    let formattedDate = '';
    if (
      this.scheduleForm.value.selectedTimeDate &&
      this.scheduleForm.value.selectedStartTime
    ) {
      const dateTimeString = `${this.scheduleForm.value.selectedTimeDate}T${this.scheduleForm.value.selectedStartTime}`;
      const parsedDate = parseISO(dateTimeString);
      formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    }

    sessionData.nextPage = nextPage;
    sessionData.appointmentInfo = this.scheduleForm.value;
    stepsData.sessionData = JSON.stringify(sessionData);
    if (this.scheduleForm.value.noneWorksForMe) {
      stepsData.appointmentRequest.appointment = null;
    } else {
      stepsData.appointmentRequest.appointment = {
        appointmentDateTime: formattedDate,
        appointmentBlockGuid: this.scheduleForm.value.selectedTime,
        schedulingPromotion: {
          displayedPromotion:
            stepsData?.appointmentRequest?.appointment?.schedulingPromotion
              ?.displayedPromotion || this.displayedPromotion,
          acceptedPromotion:
            this.displayedPromotion && !this.isDateBeyond3Days(),
        },
      };
    }

    if (isNext) {
      stepsData.status = stepsData.appointmentRequest.appointment
        ? 'Completed'
        : 'CallToSet';
    }

    return this.dataService.saveStepsData(stepsData).pipe(
      map((res) => {
        if (isNext && this.timerService) this.timerService.stop();
        return true;
      })
    );
  }

  // onClickNext = (): Observable<any> => {
  //   if (
  //     this.confirmationForm.valid &&
  //     this.confirmationForm.value.attendanceConfirmation
  //   ) {
  //     return this.saveData(
  //       NAVIGATION_MAP[PAGES.CONFIRMATION].nextButton.route,
  //       true
  //     );
  //   }
  //   this.confirmationForm.markAllAsTouched();
  //   return of(false);
  // };

  // onClickBack = (): Observable<any> => {
  //   return this.confirmationForm.valid &&
  //     this.confirmationForm.value.attendanceConfirmation
  //     ? this.saveData(NAVIGATION_MAP[PAGES.CONFIRMATION].backButton.route)
  //     : of(true);
  // };

  // saveData(nextPage, isNext?): Observable<any> {
  //   const stepsData = { ...this.dataService.stepsData };

  //   stepsData.appointmentRequest.marketingAttribution.submissionPageUrlParameters =
  //     this.marketingParams;
  //   stepsData.appointmentRequest.marketingAttribution.referrerUri =
  //     document?.referrer || '';
  //   stepsData.appointmentRequest.marketingAttribution.submissionPageUrl =
  //     window?.location?.href || '';

  //   if (isNext) {
  //     stepsData.status = stepsData.appointmentRequest.appointment
  //       ? 'Completed'
  //       : 'CallToSet';
  //   }
  //   const sessionData = JSON.parse(stepsData.sessionData);
  //   sessionData.nextPage = nextPage;
  //   stepsData.sessionData = JSON.stringify(sessionData);
  //   return this.dataService.saveStepsData(stepsData).pipe(
  //     map((res) => {
  //       if (isNext && this.timerService) this.timerService.stop();
  //       return true;
  //     })
  //   );
  // }




  private makeApiRequest(date: string, code: string): Observable<any> {
    return this.dataService.getTimeBookingData(date, code).pipe(
      map((data: { appointmentBlocks: AppointmentItem[] }) => {
        return data;
      })
    );
  }

  groupByPeriodOfTheDay(array: TimeSlot[]): {
    [key: string]: TimeSlotExt[];
  } {
    return array.reduce((result, currentItem) => {
      const period = currentItem.periodOfTheDay;
      if (!result[period]) {
        result[period] = [];
      }
      const date = parse(currentItem.startTime, 'HH:mm:ss', new Date());
      const formattedTime = format(date, 'hh:mm a');
      currentItem['time'] = formattedTime;
      result[period].push(currentItem);
      return result;
    }, {});
  }

  ngOnDestroy() {
    if (this.subscriptions) this.subscriptions.unsubscribe();
    this.cancelNotificationTimeout();
  }

  prepareDays(appointmentBlocks) {
    return appointmentBlocks.map((el: AppointmentItem): AppointmentItemExt => {
      const timeBlocks = this.groupByPeriodOfTheDay(el.timeBlocks);
      return {
        ...el,
        formattedDate: format(parseISO(el.date), 'LLL d'),
        timeSlots: Object.keys(timeBlocks).map((key) => ({
          period: key,
          slots: timeBlocks[key],
        })),
        disabled: this.isDisabledDay(el),
      };
    });
  }

  noneWorksForMe(e) {
    e.preventDefault();
    this.timerService.stop();
    this.timerService.removeAlert();
    this.scheduleForm.patchValue({
      noneWorksForMe: true,
      selectedTime: '',
      selectedTimeDate: '',
      selectedStartTime: '',
      selectedEndTime: '',
    });
    const nextButtonSub = this.onClickNext(true).subscribe((res) => {
      this.navigationService.navigateToPath(PAGES.ADDITIONAL_COMMENTS);
    });
    this.subscriptions.add(nextButtonSub);
  }

  isDisabledDay(day: AppointmentItem): boolean {
    const diff = differenceInCalendarDays(day.date, this.startDay);
    if (diff < this.numberOfDays) {
      return false;
    }
    return true;
  }
}
