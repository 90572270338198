<app-main-layout [clickNext]="onClickNext" [clickBack]="onClickBack" [activateNextButton$]="nextButtonState.activateNext$">
  <div class="checkmark-holder">
    <div class="ckeckmark">
      <img src="assets/icons/checkmark.svg" alt="">
    </div>
    <h2 class="center">{{hasDate ? appointmentDate : 'Your form is submitted!'}}</h2>
    <h4 class="center" [innerHtml]="hasDate ? subtitles[1] : subtitles[2]"></h4>
  </div>
  <div class="main-heading">
    <h4 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h4>
  </div>
  <form class="contact-form" [formGroup]="additionalCommentsForm">
    <app-form-group
      label="Comments"
      id="comments"
      formControlName="comments"
      placeholder="Please add additional comments here..."
      [textarea]="true"
      [counter]="3000"
      class="mb-24 d-block"
    ></app-form-group>
    <h4 class="mb-24" [innerHtml]="topics?.title"></h4>
    <div class="topics-wrapper">
      <app-topic-button
        *ngFor="let item of topics?.topics_list; trackBy:identify"
        [text]="item.title"
        (onItemClick)="onSelectTopic(item)"
        [selected]="item.selected"
        class="child"
      ></app-topic-button>
    </div>
  </form>
</app-main-layout>
