/// <reference types="@types/google.maps" />
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { FormGroupComponent } from 'src/app/shared/components/form-group/form-group.component';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { DataService } from 'src/app/services/data.service';
import { RadioComponent } from '../../shared/components/radio/radio.component';
import { STATES } from 'src/app/shared/constants/states';
import { ERROR_MESSAGES } from 'src/app/shared/constants/text-field';
import { Observable, Subscription, map, of } from 'rxjs';
import { FormData, Property } from 'src/app/services/data.model';
import { NAVIGATION_MAP, PAGES } from 'src/app/shared/constants/navigation-map';
import { NextButtonStateService } from 'src/app/services/next-button-state.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { userDataParams } from 'src/app/shared/constants/user-data-params';

declare var google: any;

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ContentstackModule,
    MainLayoutComponent,
    FormGroupComponent,
    RadioComponent,
    NgSelectModule,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss',
})
export class AddressComponent implements OnInit, OnDestroy, AfterViewInit {
  pageContent: any[] = [];
  addressForm: FormGroup;
  radioGroup = [];
  statesList = STATES;
  ERROR_MESSAGES = ERROR_MESSAGES;
  previouslyFilledData: {
    property: Property
  };
  sub: Subscription;
  formChangesSub: Subscription;
  autocomplete;
  inputElement: HTMLInputElement;
  interval;
  counter = 0;
  showOtherBlock = false;
  otherTextfieldMaxLength = 125;
  defaultOtherId:number = 876090099;
  userDataParams = null;
  subscription: Subscription = new Subscription();

  constructor(
    private contentstack: ContentstackQueryService,
    private fb: FormBuilder,
    private dataService: DataService,
    public nextButtonState: NextButtonStateService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {
    this.getEntry();
  }

  ngOnInit(): void {
    this.buildForm();
    if (typeof google === 'undefined') {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.google_api_key}&libraries=places&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
    const paramsSub = this.route.queryParams.subscribe((params) => {
      this.userDataParams = Object.keys(params)
        .filter((key) => userDataParams.includes(key))
        .reduce((result, key) => {
          result[key] = params[key] === 'true' ? true : params[key] === 'false' ? false : params[key];
          return result;
        }, {});
    });
    this.subscription.add(paramsSub);
  }

  ngAfterViewInit(): void {
    this.inputElement = document.getElementById("streetAddress1") as HTMLInputElement;
    if (this.inputElement) {
      // Now you can safely interact with the input element
      console.log("Input element found:", this.inputElement);
      this.loadGoogleMaps();
    } else {
      console.error("Input element not found");
      this.interval = setInterval(() => {
        this.inputElement = document.getElementById("streetAddress1") as HTMLInputElement;
        this.counter += 1;
        if (this.inputElement || this.counter === 10) {
         clearInterval(this.interval);
         if (this.inputElement) {
          this.loadGoogleMaps();
         }
        }
      }, 1000)
    }
  }

  getEntry() {
    this.contentstack
      .getEntryWithQuery('dce_page', { key: 'url', value: '/address' }, [], [])
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.defaultOtherId = Number(this.pageContent[2]?.dce_text_link?.link_id) || this.defaultOtherId;
          if (this.dataService.id) {
            const sub = this.dataService
              .getStepsData(this.dataService.id)
              .subscribe((data) => {
                this.prepopulateStepData(data);
              });
            this.subscription.add(sub);
          }
        },
        (err) => {}
      );
  }

  loadGoogleMaps() {
    if (typeof google === 'undefined') {
      window['initMap'] = () => {
        this.initAutocomplete();
      };
    } else {
      this.initAutocomplete();
    }
  }

  initAutocomplete = () => {
    const input = document.getElementById('streetAddress1') as HTMLInputElement;
    if (input) {
      this.autocomplete = new google.maps.places.Autocomplete(input, {
        componentRestrictions: { country: ['us'] },
      });
      this.autocomplete.addListener('place_changed', this.fillInAddress);
    } else {
      console.error('Input element not found');
    }
  };

  fillInAddress = () => {
    const place = this.autocomplete.getPlace();
    let streetAddress1 = '';
    let streetAddress2 = '';
    let zipcode = '';
    let city = '';
    let state = '';

    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          streetAddress1 = `${component.long_name} ${streetAddress1}`;
          break;
        }

        case 'route': {
          streetAddress1 += component.short_name;
          break;
        }

        case 'postal_code': {
          zipcode = `${component.long_name}`;
          break;
        }

        case 'subpremise': {
          streetAddress2 = `${component.long_name} ${streetAddress2}`;
          break;
        }

        case 'locality':
          city = component.long_name;
          break;

        case 'administrative_area_level_1': {
          state = component.short_name;
          break;
        }
      }
    }

    this.addressForm.patchValue({
      streetAddress1,
      streetAddress2,
      city,
      state,
      zipcode,
    });
    this.cdr.detectChanges();
  };

  buildForm() {
    this.addressForm = this.fb.group({
      streetAddress1: ['', [Validators.required, Validators.maxLength(50)]],
      streetAddress2: ['', Validators.maxLength(50)],
      city: ['', [Validators.required, Validators.maxLength(50)]],
      state: ['', Validators.required],
      zipcode: ['', Validators.required],
      propertyType: ['', Validators.required],
      propertyTypeOtherText: [''],
    });
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges(): void {
    const formChangesSub = this.addressForm.valueChanges.subscribe((value) => {
      if (value.propertyType !== this.defaultOtherId && this.showOtherBlock) {
        this.switchOther(false);
      }
      this.checkFormValidity();
    });
    this.subscription.add(formChangesSub);
  }

  checkFormValidity() {
    setTimeout((_) => {
      this.nextButtonState.emitActivateNext(this.addressForm.valid);
    });
  }

  updateFormValues() {
    this.radioGroup =
      this.pageContent[1]?.address_form_data?.ownership_check?.radio_buttons.map(
        (item: { title: string; unique_name: string }) => {
          return {
            formControlName: 'propertyType',
            label: item.title,
            id: Number(item.unique_name),
            value: Number(item.unique_name),
          };
        }
      );
    this.addressForm.patchValue({
      propertyType: this.previouslyFilledData?.property?.propertyType || '',
      streetAddress1: this.previouslyFilledData?.property?.street1 || this.userDataParams?.addressStreet1 || '',
      streetAddress2: this.previouslyFilledData?.property?.street2 || this.userDataParams?.addressStreet2 || '',
      city: this.previouslyFilledData?.property?.city || this.userDataParams?.addressCity || '',
      state: this.previouslyFilledData?.property?.state || this.userDataParams?.addressState || '',
      zipcode: this.previouslyFilledData?.property?.zipcode || this.userDataParams?.addressZipcode || '',
      propertyTypeOtherText: this.previouslyFilledData?.property?.propertyTypeOtherText || '',
    });
    if (this.previouslyFilledData?.property?.propertyTypeOtherText) {
      this.switchOther(true);
    }

    if(this.userDataParams?.addressStreet1) this.addressForm.get('streetAddress1').markAsTouched();
    if(this.userDataParams?.addressStreet2) this.addressForm.get('streetAddress2').markAsTouched();
    if(this.userDataParams?.addressCity) this.addressForm.get('city').markAsTouched();
    if(this.userDataParams?.addressState) this.addressForm.get('state').markAsTouched();
    if(this.userDataParams?.addressZipcode) this.addressForm.get('zipcode').markAsTouched();
  }

  prepopulateStepData(data: FormData) {
    if (data?.appointmentRequest?.property?.street1) {
      this.previouslyFilledData = {
        property: data?.appointmentRequest?.property
      };
    }
    this.updateFormValues();
  }

  onClickNext = (): Observable<any> => {
    if (this.addressForm.valid) {
      return this.saveData(NAVIGATION_MAP[PAGES.ADDRESS].nextButton.route);
    }
    this.addressForm.markAllAsTouched();
    return of(false);
  };

  onClickBack = (): Observable<any> => {
    return this.addressForm.valid ? this.saveData(NAVIGATION_MAP[PAGES.ADDRESS].backButton.route) : of(true);
  };

  saveData(nextPage): Observable<any> {
    const stepsData = {...this.dataService.stepsData};

    stepsData.appointmentRequest.property.street1 = this.addressForm.value.streetAddress1;
    stepsData.appointmentRequest.property.street2 = this.addressForm.value.streetAddress2;
    stepsData.appointmentRequest.property.city = this.addressForm.value.city;
    stepsData.appointmentRequest.property.zipcode = this.addressForm.value.zipcode;
    stepsData.appointmentRequest.property.state = this.addressForm.value.state;
    stepsData.appointmentRequest.property.propertyType = this.addressForm.value.propertyType;
    stepsData.appointmentRequest.property.propertyTypeOtherText = this.addressForm.value.propertyTypeOtherText;

    const sessionData = JSON.parse(stepsData.sessionData);
    sessionData.nextPage = nextPage;
    stepsData.sessionData = JSON.stringify(sessionData);
    return this.dataService
      .saveStepsData(stepsData)
      .pipe(
        map((res) => {
          return true;
        })
      );
  }

  addOtherControlValidation(add: boolean) {
    const other = this.addressForm.get('propertyTypeOtherText');
    if (add) {
      this.addressForm.patchValue({ propertyType: this.defaultOtherId });
      other.setValidators([
        Validators.required,
        Validators.maxLength(this.otherTextfieldMaxLength),
      ]);
    } else {
      if(this.addressForm.get('propertyType').value === this.defaultOtherId){
        this.addressForm.patchValue({ propertyType: '' });
      }
      other.patchValue('');
      other.clearValidators();
      other.updateValueAndValidity();
    }
  }

  switchOther(show, e?) {
    if(e) e.preventDefault();
    this.showOtherBlock = show;
    this.addOtherControlValidation(show);
    this.checkFormValidity();
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
