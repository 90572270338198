export const userDataParams = [
  'firstName',
  'lastName',
  'phoneNumber',
  'emailAddress',
  'addressStreet1',
  'addressStreet2',
  'addressCity',
  'addressState',
  'addressZipcode',
  'isPPL',
  'originationSource',
  'leadId'
];
