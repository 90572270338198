import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { Subscription, fromEvent, Observable, map, of } from 'rxjs';
import { PAGES } from 'src/app/shared/constants/navigation-map';
import { NavigationService } from 'src/app/services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FormGroupComponent } from 'src/app/shared/components/form-group/form-group.component';
import { NextButtonStateService } from 'src/app/services/next-button-state.service';
import { VALIDATION_PATTERNS } from 'src/app/shared/constants/text-field';
import {
  FORBIDDEN_PHONES,
  forbiddenPhoneValidator,
} from 'src/app/shared/constants/phone-validator';
import { FormData, Customer } from 'src/app/services/data.model';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { CheckboxComponent } from 'src/app/shared/components/checkbox/checkbox.component';
import { environment } from 'src/environments/environment';
import { marketingAttributes } from 'src/app/shared/constants/marketing-params';
import { userDataParams } from 'src/app/shared/constants/user-data-params';
import { TimerService } from 'src/app/services/timer.service';

@Component({
  selector: 'app-intro',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    ReactiveFormsModule,
    FormGroupComponent,
    ButtonComponent,
    CheckboxComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './intro.component.html',
  styleUrl: './intro.component.scss',
})
export class IntroComponent implements OnInit, OnDestroy {
  public selected = '';
  pageContent: any;
  pageContentContact: any;
  personalInfoForm: FormGroup;
  otherTextfieldMaxLength = 125;
  previouslyFilledData: {
    customer: Customer;
    marketingConsent: boolean;
  };
  isEmbedded: boolean = false;
  marketingParams: any[];
  userDataParams = null;
  domainNames = {
    development: 'https://web-use-d365ce-digital-dv.azurewebsites.net/',
    production: 'https://appointment.windownation.com/',
  };

  subscription: Subscription = new Subscription();
  marketTitle: string;

  constructor(
    private contentstack: ContentstackQueryService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private fb: FormBuilder,
    public nextButtonState: NextButtonStateService,
    private timerService: TimerService
  ) {
    this.getEntry();
    this.getContactEntry();
  }

  ngOnInit(): void {
    this.buildForm();
    history.pushState(null, '');

    const popStateSub = fromEvent(window, 'popstate').subscribe((_) => {
      history.pushState(null, '');
    });

    if (this.dataService.id) {
      const getDataSub = this.dataService
        .getStepsData(this.dataService.id)
        .subscribe((data) => {
          this.prepopulateStepData(data);
        });
      this.subscription.add(getDataSub);
    }
    this.timerService.stop();
    this.timerService.removeAlert();
    const routeSub = this.route.data.subscribe((data) => {
      this.isEmbedded = data['embed'] || false;
      if (this.isEmbedded) {
        this.navigationService.setIsEmbedded(true);
      }
    });
    const paramsSub = this.route.queryParams.subscribe((params) => {
      this.marketingParams = Object.keys(params)
        .filter((key) => marketingAttributes.includes(key))
        .map((key) => ({ parameter: key, value: params[key] }));
      this.marketTitle = this.marketingParams.find((item: any) => item.parameter === 'market_name')?.value;
      this.userDataParams = Object.keys(params)
        .filter((key) => userDataParams.includes(key))
        .reduce((result, key) => {
          result[key] = params[key] === 'true' ? true : params[key] === 'false' ? false : params[key];
          return result;
        }, {});

      if (Object.keys(this.userDataParams).length !== 0) {
        this.prepopulateDataFromParams();
      }
    });
    this.subscription.add(popStateSub);
    this.subscription.add(routeSub);
    this.subscription.add(paramsSub);
  }

  getEntry() {
    this.contentstack
      .getEntryWithQuery('dce_page', { key: 'url', value: '/home' }, [], [])
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.navigationService.setHasOfferBar(
            !!this.pageContent[0]?.dce_intro?.offer
          );
        },
        (err) => {}
      );
  }

  getContactEntry() {
    this.contentstack
      .getEntryWithQuery('dce_page', { key: 'url', value: '/contact' }, [], [])
      .then(
        (entry) => {
          this.pageContentContact = entry[0][0]?.page_components;
        },
        (err) => {}
      );
  }

  onClickNext = () => {
    const saveDataSub = this.saveData(PAGES.ADDRESS).subscribe();
    this.subscription.add(saveDataSub);
    this.personalInfoForm.markAllAsTouched();
  };

  buildForm() {
    this.personalInfoForm = this.fb.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(VALIDATION_PATTERNS.text),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(VALIDATION_PATTERNS.text),
        ],
      ],
      primaryEmail: [
        '',
        [Validators.required, Validators.pattern(VALIDATION_PATTERNS.email)],
      ],
      primaryPhone: [
        '',
        [Validators.required, forbiddenPhoneValidator(FORBIDDEN_PHONES)],
      ],
      contactPreference: [true, [Validators.required, Validators.requiredTrue]],
    });
  }

  updateFormValues() {
    this.personalInfoForm.patchValue({
      firstName: this.previouslyFilledData?.customer?.firstName || '',
      lastName: this.previouslyFilledData?.customer?.lastName || '',
      primaryEmail: this.previouslyFilledData?.customer?.email || '',
      primaryPhone: this.previouslyFilledData?.customer?.phonePrimary || '',
      contactPreference: this.previouslyFilledData?.marketingConsent || '',
    });
  }

  prepopulateDataFromParams() {
    this.personalInfoForm.patchValue({
      firstName: this.userDataParams?.firstName || '',
      lastName: this.userDataParams?.lastName || '',
      primaryEmail: this.userDataParams?.emailAddress || '',
      primaryPhone: this.userDataParams?.phoneNumber || '',
    });
    if(this.userDataParams?.firstName) this.personalInfoForm.get('firstName').markAsTouched();
    if(this.userDataParams?.lastName) this.personalInfoForm.get('lastName').markAsTouched();
    if(this.userDataParams?.emailAddress) this.personalInfoForm.get('primaryEmail').markAsTouched();
    if(this.userDataParams?.phoneNumber) this.personalInfoForm.get('primaryPhone').markAsTouched();
  }

  prepopulateStepData(data: FormData) {
    if (
      data?.appointmentRequest?.customer &&
      data?.appointmentRequest?.marketingAttribution
    ) {
      this.previouslyFilledData = {
        customer: data.appointmentRequest.customer,
        marketingConsent: this.previouslyFilledData?.marketingConsent || true,
      };
    }
    this.updateFormValues();
  }

  saveData(nextPage): Observable<any> {
    const stepsData = { ...this.dataService.stepsData };
    stepsData.appointmentRequest.customer.firstName =
      this.personalInfoForm.value.firstName;
    stepsData.appointmentRequest.customer.lastName =
      this.personalInfoForm.value.lastName;
    stepsData.appointmentRequest.customer.email =
      this.personalInfoForm.value.primaryEmail;
    stepsData.appointmentRequest.customer.phonePrimary =
      this.personalInfoForm.value.primaryPhone;
    stepsData.appointmentRequest.marketingConsent =
      this.personalInfoForm.value.contactPreference;
    const sessionData = JSON.parse(stepsData.sessionData);
    sessionData.nextPage = nextPage;
    stepsData.sessionData = JSON.stringify(sessionData);
    stepsData.appointmentRequest.marketingAttribution.submissionPageUrlParameters =
      this.marketingParams;
    stepsData.appointmentRequest.marketingAttribution.referrerUri =
      document?.referrer || '';
    stepsData.appointmentRequest.marketingAttribution.submissionPageUrl =
      window?.location?.href || '';

    if(this.userDataParams.isPPL) stepsData.appointmentRequest.isPPL = true;
    stepsData.appointmentRequest.originationSource = this.userDataParams.originationSource || '';
    stepsData.appointmentRequest.leadId = this.userDataParams.leadId || '';

    return this.dataService.saveStepsData(stepsData).pipe(
      map((res) => {
        if (this.isEmbedded) {
          let urlString = !!environment.production
            ? this.domainNames.production
            : this.domainNames.development;
          urlString = `${urlString}${nextPage}?id=${res.id}`;
          for (let index = 0; index < this.marketingParams.length; index++) {
            if (index === 0 && this.marketingParams[index].parameter) {
              urlString = `${urlString}?${this.marketingParams[index].parameter}=${this.marketingParams[index].value}`;
            } else if (this.marketingParams[index].parameter) {
              urlString = `${urlString}&${this.marketingParams[index].parameter}=${this.marketingParams[index].value}`;
            }
          }
          window.open(urlString);
          return of(false);
        } else {
          this.navigationService.navigateToPath(nextPage, { id: res.id });
        }
        return false;
      })
    );
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
    this.navigationService.setHasOfferBar(false);
  }
}
