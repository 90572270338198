export const marketingAttributes = [
  'utm_source',
  'utm_campaign',
  'utm_code',
  'utm_medium',
  'utm_term',
  'utm_source_platform',
  'utm_creative_format',
  'utm_marketing_tactic',
  'utm_geo',
  'gclid',
  'fbclid',
  'market_name'
];
