import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { format, subSeconds, differenceInSeconds } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private readonly TIMER_KEY = 'timer-progress';
  private readonly ALERT_KEY = 'timer-alert';
  private readonly INITIAL_TIME = 300; // 5 minutes in seconds
  private timer$: BehaviorSubject<number> = new BehaviorSubject(
    this.INITIAL_TIME
  );
  private timerSubscription: Subscription | null = null;
  public running: boolean = false;
  public showAlert: boolean = false;

  private runningSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public running$: Observable<boolean> = this.runningSubject.asObservable();

  private showAlertSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public showAlert$: Observable<boolean> = this.showAlertSubject.asObservable();

  private okButtonClickSubject = new Subject<void>();
  public okButtonClick$ = this.okButtonClickSubject.asObservable();

  constructor() {
    this.loadTimer();
  }

  get time() {
    return this.timer$.asObservable().pipe(
      map((seconds) => format(subSeconds(new Date(0), -seconds), 'mm:ss')) // format for countdown
    );
  }

  get timeInSeconds() {
    return this.timer$.asObservable().pipe(map((seconds) => seconds));
  }

  start() {
    if (this.running && this.timerSubscription) return; // Do not start if already running or time is zero
    this.running = true;
    this.runningSubject.next(true);
    const time = localStorage.getItem(this.TIMER_KEY);
    if (!time) {
      this.saveTimer(new Date());
      this.timer$.next(this.INITIAL_TIME);
      this.showAlert = false;
      this.showAlertSubject.next(false);
      localStorage.setItem(this.ALERT_KEY, String(false));
    }
    this.timerSubscription = interval(1000)
      .pipe(
        tap(() => {
          const newTime = this.timer$.value - 1;
          this.timer$.next(newTime);
          if (newTime <= 0) {
            this.showAlert = true;
            this.showAlertSubject.next(true);
            localStorage.setItem(this.ALERT_KEY, String(true));
            this.stop();
          }
        })
      )
      .subscribe();
  }

  stop() {
    this.running = false;
    this.runningSubject.next(false);
    localStorage.removeItem(this.TIMER_KEY);
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  reset() {
    this.stop();
    this.timer$.next(this.INITIAL_TIME);
    this.showAlert = false;
    this.showAlertSubject.next(false);
    this.saveTimer(new Date());
    this.start();
  }

  removeAlert() {
    this.showAlert = false;
    this.showAlertSubject.next(false);
    localStorage.removeItem(this.ALERT_KEY);
  }

  private saveTimer(date: Date) {
    localStorage.setItem(this.TIMER_KEY, date.toString());
  }

  private loadTimer() {
    const savedTime = localStorage.getItem(this.TIMER_KEY);
    const alert = localStorage.getItem(this.ALERT_KEY);
    if (alert && alert === 'true') {
      this.showAlertSubject.next(true);
    } else {
      this.showAlertSubject.next(false);
      localStorage.removeItem(this.ALERT_KEY);
    }
    if (savedTime) {
      const diff = differenceInSeconds(new Date(), savedTime);
      if (diff > this.INITIAL_TIME) {
        localStorage.removeItem(this.TIMER_KEY);
      } else {
        this.timer$.next(this.INITIAL_TIME - diff);
        this.start();
      }
    } else {
      localStorage.removeItem(this.TIMER_KEY);
    }
  }

  handleOkButtonClick(): void {
    this.removeAlert();
    this.okButtonClickSubject.next();
  }
}
