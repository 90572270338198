import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, map, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { FormData } from './data.model';
import { INITIAL_DATA } from './data.initial';
import { environment } from 'src/environments/environment';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private formDataSubject = new BehaviorSubject<FormData>(INITIAL_DATA);
  formData$ = this.formDataSubject.asObservable();

  id: string;
  stepsData: FormData = INITIAL_DATA;
  resumeBooking: boolean = false;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        if (params['id']) {
          this.id = params['id'];
          if (params['resume']) {
            this.resumeBooking = !!params['resume'];
          }
        }
      }
    });
  }

  getFormData(): FormData {
    return this.formDataSubject.value;
  }

  get username() {
    return this.stepsData?.appointmentRequest?.customer?.firstName || '';
  }

  saveStepsData(newData: FormData) {
    newData.id = this.id || '';
    console.log('DATA TO SEND -----', newData);
    return this.http
      .put<any>(`${environment.api_url}/Appointments`, newData)
      .pipe(
        map((data: any) => {
          this.stepsData = newData;
          this.id = data.id;
          return {
            id: data.id,
            data: newData,
          };
        })
      );
  }

  getStepsData(id) {
    return this.http.get<any>(`${environment.api_url}/Appointments/${id}`).pipe(
      map((data: FormData) => {
        this.stepsData = data;
        this.id = id;
        if (this.resumeBooking) {
          this.redirectToTheLastPage();
        }
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        if(error.status === 404){
          this.navigationService.navigateToPath('', { id: null });
          this.id = '';
        }
        return EMPTY;
      })
    );
  }

  resetStepsData() {
    this.stepsData = INITIAL_DATA;
    this.id = null;
  }

  redirectToTheLastPage() {
    this.resumeBooking = false;
    const sessionData = JSON.parse(this.stepsData.sessionData);
    if (sessionData.nextPage) {
      this.navigationService.navigateToPath(
        sessionData.nextPage,
        {
          resume: null,
        }
      );
    }
  }

  getTimeBookingData(date: string, code: string): Observable<any> {
    return this.http.get<any>(
      `${environment.api_url}/appointmentblocks?postalCode=${code}&startDate=${date}`
    );
  }

  getReviews(): Observable<any> {
    return this.http.post(
      `${environment.birdeye_url}/resources/v1/review/businessId/${environment.birdeye_business_id}?api_key=${environment.birdeye_api_key}&rating=5&count=50&max=50`,
      { sources: ['google'] }
    );
  }

  getReviewSummary(): Observable<any> {
    return this.http.get(
      `${environment.birdeye_url}/resources/v1/review/businessid/${environment.birdeye_business_id}/summary?api_key=${environment.birdeye_api_key}`
    );
  }

  propertyValidation(): Observable<any> {
    const data = this.stepsData;
    return this.http.post(`${environment.api_url}/PropertyValidation`, {
      address: `${data.appointmentRequest.property.street1} ${data.appointmentRequest.property.city} ${data.appointmentRequest.property.state} ${data.appointmentRequest.property.zipcode}`,
      firstName: data.appointmentRequest.customer.firstName,
      lastName: data.appointmentRequest.customer.lastName,
    });
  }

  serviceAvailability(): Observable<any> {
    const data = this.stepsData;
    return this.http.get(
      `${environment.api_url}/ServiceAvailability/${data.appointmentRequest.property.zipcode}`
    );
  }
}
