import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { CounterComponent } from 'src/app/shared/components/counter/counter.component';
import { FormData } from 'src/app/services/data.model';
import { DataService } from 'src/app/services/data.service';
import { Observable, Subscription, map, of } from 'rxjs';
import { NAVIGATION_MAP, PAGES } from 'src/app/shared/constants/navigation-map';
import { NextButtonStateService } from 'src/app/services/next-button-state.service';

@Component({
  selector: 'app-door-count',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    MainLayoutComponent,
    CounterComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './door-count.component.html',
  styleUrl: './door-count.component.scss',
})
export class DoorCountComponent implements OnInit, OnDestroy {
  pageContent: any = [];
  counterValue = 0;
  counterMinValue = 0;
  counterMaxValue = 10;
  counterStep = 1;
  counterInitialValue = 0;
  doorImage: string;
  valid = true;
  sub: Subscription;
  windowCount: number = 0;

  constructor(
    private contentstack: ContentstackQueryService,
    private dataService: DataService,
    public nextButtonState: NextButtonStateService
  ) {
    this.getEntry();
  }

  ngOnInit() {}

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/door-count' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.doorImage = this.pageContent[1]?.dce_door_count?.door_image?.url;
          if (this.dataService.id) {
            this.sub = this.dataService
              .getStepsData(this.dataService.id)
              .subscribe((data) => {
                this.prepopulateStepData(data);
              });
          }
        },
        (err) => {}
      );
  }

  prepopulateStepData(data: FormData) {
    if (data?.appointmentRequest?.project?.doorCount) {
      this.counterInitialValue = data.appointmentRequest.project.doorCount;
    }
    if (data?.appointmentRequest?.project?.windowCount) {
      this.windowCount = Number(data.appointmentRequest.project.windowCount);
    }
    this.checkFormValidity();
  }

  checkFormValidity() {
    setTimeout((_) => {
      this.nextButtonState.emitActivateNext(this.valid);
    });
  }

  onCounterValueChange({
    value,
    valid,
  }: {
    value: number;
    valid: boolean;
  }): void {
    this.counterValue = value;
    this.valid = valid;
    this.checkFormValidity();
  }

  onClickNext = (): Observable<any> => {
    return this.valid ? this.saveData(this.windowCount === 0 ? PAGES.SOURCE_TYPE : NAVIGATION_MAP[PAGES.DOOR_COUNT].nextButton.route) : of(false);
  };

  onClickBack = (): Observable<any> => {
    return this.valid ? this.saveData(NAVIGATION_MAP[PAGES.DOOR_COUNT].backButton.route) : of(true);
  };

  saveData(nextPage): Observable<any> {
    const stepsData = {...this.dataService.stepsData};
    stepsData.appointmentRequest.project.doorCount = this.counterValue;
    const sessionData = JSON.parse(stepsData.sessionData);
    sessionData.nextPage = nextPage;
    stepsData.sessionData = JSON.stringify(sessionData);
    return this.dataService
      .saveStepsData(stepsData)
      .pipe(
        map((res) => {
          return nextPage;
        })
      );
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }
}
