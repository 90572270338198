import { FormData } from './data.model';

export const INITIAL_DATA: FormData = {
  id: '',
  appointmentRequest: {
    customer: {
      firstName: '',
      lastName: '',
      email: '',
      phonePrimary: '',
    },
    marketingConsent: false,
    marketingAttribution: {
      sourceTypeEnum: 0,
      sourceTypeOtherText: '',
      referrerUri: '',
      submissionPageUrl: '',
      submissionPageUrlParameters: [],
    },
    property: {
      street1: '',
      street2: '',
      city: '',
      zipcode: '',
      zipcodeValid: false,
      zipcodeGuid: '',
      marketSegmentGuid: '',
      state: '',
      ownership: {
        multipleOwners: false,
        additionalOwners: [],
        ownershipValidated: false,
      },
      propertyType: 876090000, //primary residence
      propertyTypeOtherText: '',
    },
    project: {
      doorCount: 0,
      windowCount: 0,
      windowReasonEnum: [],
      windowReasonOtherText: '',
      timeframeEnum: 0,
    },
    insideSales: false,
    insideSalesReasonEnum: 0,
    customerComments: '',
    isPPL: false,
    originationSource: '',
    leadId: ''
  },
  sessionData: '{}',
  status: 'New',
};
