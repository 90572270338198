export const PAGES = {
  PROCESS_INFO: 'process-info',
  EXPLORATORY_PROCESS_INFO: 'exploratory-process-info',
  NAME: 'name',
  ADDRESS: 'address',
  HOMEOWNER_LIST: 'homeowner-list',
  WINDOW_COUNT: 'window-count',
  DOOR_COUNT: 'door-count',
  WINDOW_ISSUES: 'window-issues',
  INSIDE_SALES: 'inside-sales', // TODO: not used
  DO_NOT_SERVICE_AREA: 'do-not-service-area',
  NOT_A_HOMEOWNER: 'not-a-homeowner', // TODO: not used
  ADDITIONAL_COMMENTS: 'additional-comments',
  EXPLORE_RESOURCES: 'explore-resources',
  ENERGY_EFFICIENCY: 'energy-efficiency',
  FINANCING_PLANS: 'financing-plans',
  FINANCING_EDUCATION: 'financing-education',
  WHATS_NEXT: 'whats-next',
  INSTALLATION_PROCESS: 'installation-process',
  CONFIRMATION: 'confirmation',
  TIME_DATE_BOOKING: 'time-date-booking',
  REQUEST_CALL: 'request-call',
  CALL_CENTER: 'call-center',
  SOURCE_TYPE: 'source-type',
  EMBED_GET_STARTED: 'embed-get-started'
};

export const WITHOUT_ID_WHITELIST = [
  PAGES.PROCESS_INFO,
  PAGES.EXPLORATORY_PROCESS_INFO,
  PAGES.NAME,
  PAGES.EMBED_GET_STARTED,
  '',
];

export const PAGES_TO_VALIDATE_RANDOM_NAVIGATION = [
  PAGES.NAME,
  PAGES.ADDRESS,
  PAGES.HOMEOWNER_LIST,
  PAGES.WINDOW_COUNT,
  PAGES.DOOR_COUNT,
  PAGES.WINDOW_ISSUES,
  PAGES.ADDITIONAL_COMMENTS,
  PAGES.CONFIRMATION,
  PAGES.TIME_DATE_BOOKING,
  PAGES.SOURCE_TYPE,
];

export const NAVIGATION_MAP = {
  [PAGES.PROCESS_INFO]: {
    nextButton: {
      text: 'Next',
      route: PAGES.NAME,
    },
    backButton: {
      text: 'Back',
      route: '',
    },
  },
  [PAGES.EXPLORATORY_PROCESS_INFO]: {
    nextButton: {
      text: 'Next',
      route: PAGES.NAME,
    },
    backButton: {
      text: 'Back',
      route: '',
    },
  },
  [PAGES.NAME]: {
    nextButton: {
      text: 'Next',
      route: PAGES.ADDRESS,
    },
    backButton: {
      text: 'Back',
      route: '',
    },
    status: { activeIndex: 0 },
    dataName: 'personalInfo',
  },
  [PAGES.ADDRESS]: {
    nextButton: {
      text: 'Next',
      route: PAGES.HOMEOWNER_LIST,
    },
    backButton: {
      text: 'Back',
      route: '',
    },
    status: { activeIndex: 0 },
    dataName: 'addressInfo',
  },
  [PAGES.HOMEOWNER_LIST]: {
    nextButton: {
      text: 'Next',
      route: PAGES.WINDOW_COUNT,
    },
    backButton: {
      text: 'Back',
      route: PAGES.ADDRESS,
    },
    status: { activeIndex: 0 },
    dataName: 'homeownerInfo',
  },
  [PAGES.WINDOW_COUNT]: {
    nextButton: {
      text: 'Next',
      route: PAGES.DOOR_COUNT,
    },
    backButton: {
      text: 'Back',
      route: PAGES.HOMEOWNER_LIST,
    },
    status: { activeIndex: 1 },
    dataName: 'windowCount',
  },
  [PAGES.DOOR_COUNT]: {
    nextButton: {
      text: 'Next',
      route: PAGES.WINDOW_ISSUES,
    },
    backButton: {
      text: 'Back',
      route: PAGES.WINDOW_COUNT,
    },
    status: { activeIndex: 1 },
    dataName: 'doorCount',
  },
  [PAGES.WINDOW_ISSUES]: {
    nextButton: {
      text: 'Next',
      route: PAGES.SOURCE_TYPE,
    },
    backButton: {
      text: 'Back',
      route: PAGES.DOOR_COUNT,
    },
    status: { activeIndex: 1 },
    dataName: 'windowIssues',
  },
  [PAGES.INSIDE_SALES]: {
    dataName: 'insideSales',
    exitButton: {
      text: 'Exit Form',
      route: '',
    },
  },
  [PAGES.DO_NOT_SERVICE_AREA]: {
    dataName: 'doNotServiceArea',
    exitButton: {
      text: 'Exit Form',
      route: '',
    },
  },
  [PAGES.NOT_A_HOMEOWNER]: {
    dataName: 'notAHomeowner',
    exitButton: {
      text: 'Exit Form',
      route: '',
    },
  },
  [PAGES.ADDITIONAL_COMMENTS]: {
    dataName: 'additionalComments',
    nextButton: {
      text: 'Submit',
      route: PAGES.EXPLORE_RESOURCES,
      position: 'center',
      noIcon: true,
    },
  },
  [PAGES.EXPLORE_RESOURCES]: {
    dataName: 'exploreResources',
  },
  [PAGES.ENERGY_EFFICIENCY]: {
    backButton: {
      text: 'Back',
      route: PAGES.EXPLORE_RESOURCES,
      useHistory: true,
    },
    dataName: 'energyEfficiency',
  },
  [PAGES.FINANCING_PLANS]: {
    backButton: {
      text: 'Back',
      route: PAGES.EXPLORE_RESOURCES,
      useHistory: true,
    },
    dataName: 'financingPlans',
  },
  [PAGES.FINANCING_EDUCATION]: {
    backButton: {
      text: 'Back',
      route: PAGES.EXPLORE_RESOURCES,
      useHistory: true,
    },
    dataName: 'financingEducation',
  },
  [PAGES.WHATS_NEXT]: {
    dataName: 'whatsNext',
  },
  [PAGES.INSTALLATION_PROCESS]: {
    backButton: {
      text: 'Back',
      route: '',
      useHistory: true,
    },
    dataName: 'installationProcess',
  },
  [PAGES.CONFIRMATION]: {
    dataName: 'confirmation',
    nextButton: {
      text: 'Book Now',
      route: PAGES.WHATS_NEXT,
      position: 'center',
      noIcon: true,
    },
    backButton: {
      text: 'Back',
      route: PAGES.ADDITIONAL_COMMENTS,
    }
  },
  [PAGES.TIME_DATE_BOOKING]: {
    dataName: 'timeDateBooking',
    nextButton: {
      text: 'Book appointment',
      route: PAGES.ADDITIONAL_COMMENTS,
    },
    backButton: {
      text: 'Back',
      route: PAGES.SOURCE_TYPE,
    }
  },
  [PAGES.SOURCE_TYPE]: {
    nextButton: {
      text: 'Next',
      route: PAGES.TIME_DATE_BOOKING,
    },
    backButton: {
      text: 'Back',
      route: PAGES.WINDOW_ISSUES,
    },
    status: { activeIndex: 1 },
    dataName: 'sourceType',
  },
  [PAGES.CALL_CENTER]: {
    dataName: 'callCenter',
    exitButton: {
      text: 'Exit Form',
      route: '',
    },
  },
  [PAGES.EMBED_GET_STARTED]: {
    nextButton: {
      text: 'Start my estimate',
      route: PAGES.ADDRESS,
    },
    dataName: 'personalInfo',
  },
};
