import { Routes } from "@angular/router";
import { IntroComponent } from "./components/intro/intro.component";
import { ProcessInfoComponent } from "./components/process-info/process-info.component";
import { RequestCallComponent } from "./components/request-call/request-call.component";
import { NameComponent } from "./components/name/name.component";
import { WindowCountComponent } from "./components/window-count/window-count.component";
import { AddressComponent } from "./components/address/address.component";
import { ExploreResourcesComponent } from "./components/explore-resources/explore-resources.component";
import { EnergyEfficiencyComponent } from "./components/energy-efficiency/energy-efficiency.component";
import { FinancingPlansComponent } from "./components/financing-plans/financing-plans.component";
import { FinancingEducationComponent } from "./components/financing-education/financing-education.component";
import { DoorCountComponent } from "./components/door-count/door-count.component";
import { WindowIssuesComponent } from "./components/window-issues/window-issues.component";
import { InsideSalesComponent } from "./components/inside-sales/inside-sales.component";
import { DoNotServiceAreaComponent } from "./components/do-not-service-area/do-not-service-area.component";
import { NotAHomeownerComponent } from "./components/not-a-homeowner/not-a-homeowner.component";
import { HomeownerComponent } from "./components/homeowner/homeowner.component";
import { WhatsNextComponent } from "./components/whats-next/whats-next.component";
import { AdditionalCommentsComponent } from "./components/additional-comments/additional-comments.component";
import { InstallationProcessComponent } from './components/installation-process/installation-process.component';
import { ConfirmationComponent } from "./components/confirmation/confirmation.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { TimeDateBookingComponent } from './components/time-date-booking/time-date-booking.component';
import { ExploratoryProcessInfoComponent } from './components/exploratory-process-info/exploratory-process-info.component';
import { CallCenterComponent } from "./components/call-center/call-center.component";
import { SourceTypeComponent } from "./components/source-type/source-type.component";

export const routes: Routes = [
  {
    path: "",
    component: IntroComponent,
    title: "Home page",
    data: { embed: false }
  },
  {
    path: "embed-get-started",
    component: IntroComponent,
    title: "",
    data: { embed: true }
  },
  {
    path: "request-call",
    component: RequestCallComponent,
    title: "Request Call",
  },
  {
    path: "process-info",
    component: ProcessInfoComponent,
    title: "Process Info",
  },
  {
    path: "exploratory-process-info",
    component: ExploratoryProcessInfoComponent,
    title: "Exploratory Process Info",
  },
  {
    path: "name",
    component: NameComponent,
    title: "Personal Info",
  },
  {
    path: "address",
    component: AddressComponent,
    title: "Address Info",
  },
  {
    path: "homeowner-list",
    component: HomeownerComponent,
    title: "Homeowner list",
  },
  {
    path: "window-count",
    component: WindowCountComponent,
    title: "Window count",
  },
  {
    path: "door-count",
    component: DoorCountComponent,
    title: "Door count",
  },
  {
    path: "window-issues",
    component: WindowIssuesComponent,
    title: "Window issues",
  },
  {
    path: "source-type",
    component: SourceTypeComponent,
    title: "Source Type",
  },
  {
    path: "inside-sales",
    component: InsideSalesComponent,
    title: "Inside sales",
  },
  {
    path: "do-not-service-area",
    component: DoNotServiceAreaComponent,
    title: "Don't service area",
  },
  {
    path: "not-a-homeowner",
    component: NotAHomeownerComponent,
    title: "Not a homeowner",
  },
  {
    path: "additional-comments",
    component: AdditionalCommentsComponent,
    title: "Additional Comments",
  },
  {
    path: "explore-resources",
    component: ExploreResourcesComponent,
    title: "Explore resources",
  },
  {
    path: "energy-efficiency",
    component: EnergyEfficiencyComponent,
    title: "Energy efficiency",
  },
  {
    path: "financing-plans",
    component: FinancingPlansComponent,
    title: "Financing plans",
  },
  {
    path: "financing-education",
    component: FinancingEducationComponent,
    title: "Financing education",
  },
  {
    path: "whats-next",
    component: WhatsNextComponent,
    title: "What's next",
  },
  {
    path: "installation-process",
    component: InstallationProcessComponent,
    title: "Installation process",
  },
  {
    path: "confirmation",
    component: ConfirmationComponent,
    title: "Confirmation",
  },
  {
    path: "time-date-booking",
    component: TimeDateBookingComponent,
    title: "Time and date booking",
  },
  {
    path: "call-center",
    component: CallCenterComponent,
    title: "Call Center",
  },
  {
    path: "**",
    pathMatch: "full",
    component: NotFoundComponent,
    title: "Not found",
  },
];
